<template>
    <div>
            <Card>
                <template #title>
                    <Toolbar>
                        <template #start>
                        </template>
                        <template #end>
                            <Button 
                            v-if="!pageNoWork"
                            :label="$t('save')" 
                            :disabled="isDisabledSave"
                            :class="{ 'is-pulse' : !isDisabledSave }"
                            icon="pi pi-save" class="p-button-sm p-button-success" 
                    @click="toSaveCustomize" />
                        </template>
                    </Toolbar>
                </template>
                <template #content>
                    <no-work-page 
                    @refresh-request="getStyles" 
                    :loadingFlag="loadingFlag"
                    v-if="pageNoWork"
                    textError="just_error"
                    /> 
                                <div class="rebuild-ava" v-else>
                                    <div class="rebuild-ava__wrapper">
                                        <div class="rebuild-ava__content d-flex align-center">
                                            <div class="column-three column-three_no-padding">
                                                <div class="rebuild-ava__item">
                                                    <div class="d-flex align-end gap-2">
                                                        <div class="d-flex flex-column align-center">
                                                            <Skeleton size="5rem" v-if="loadingFlag"></Skeleton>
                                                            <button v-else type="button" class="rebuild-ava__image _cursor-default" :class="{ '_no-photo _cursor-pointer' : !isHaveAnyLogo}">
                                                                <template v-if="isHaveAnyLogo">
                                                                    <div class="delete-close" @click="deleteImage('logo')">
                                                                        <v-icon color="var(--surface-100)">mdi-close</v-icon>
                                                                    </div>
                                                                    <img 
                                                                       :src="getLogoPreview"
                                                                        alt="logo" />
                                                                </template>
                                                                    
                                                                <div v-else class="no-photo-plus" @click="triggerUploadInput('logo')">
                                                                    <input type="file" ref="imagePreview" accept="image/png, image/jpeg, image/svg+xml" style="display: none"
                                                                    id="logo-input-id" @change="uploadLogo($event)">
                                                                    <v-icon color="var(--surface-900)">mdi-plus</v-icon>
                                                                </div>
                                                                
                                                            </button>
                                                            <b class="mt-1">
                                                                {{$t('logo')}}<span class="c-red">*</span>
                                                            </b>
                                                        </div>
                                                        <div class="d-flex flex-column align-center">
                                                            <Skeleton size="4rem" v-if="loadingFlag"></Skeleton>
                                                            <button v-else type="button" class="rebuild-ava__image rebuild-ava__image_favicon _cursor-default" :class="{ '_no-photo _cursor-pointer' : !isHaveAnyFavicon}">
                                                                <template v-if="isHaveAnyFavicon">
                                                                    <div class="delete-close" @click="deleteImage('favicon')">
                                                                        <v-icon color="var(--surface-100)">mdi-close</v-icon>
                                                                    </div>
                                                                    <img 
                                                                       :src="getFaviconPreview"
                                                                        alt="favicon" />
                                                                </template>
                                                                    
                                                                <div v-else class="no-photo-plus" @click="triggerUploadInput('favicon')">
                                                                    <input type="file" ref="imagePreview" accept="image/png, image/jpeg, image/svg+xml, image/x-icon" 
                                                                    style="display: none"
                                                                    id="favicon-input-id" @change="uploadFavicon($event)">
                                                                    <v-icon color="var(--surface-900)">mdi-plus</v-icon>
                                                                </div>
                                                                
                                                            </button>
                                                            <b class="mt-1">
                                                                {{$t('favicon')}}<span class="c-red">*</span>
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="rebuild-ava__item" v-if="isAgent">
                                                    <div class="d-flex align-end gap-2">
                                                        <div class="d-flex flex-column align-center">
                                                            <Skeleton size="8rem" v-if="loadingFlag"></Skeleton>
                                                            <button v-else type="button" class="rebuild-ava__image rebuild-ava__image_big _cursor-default" :class="{ '_no-photo _cursor-pointer' : !isHaveAnyJackpotWin}">
                                                                <template v-if="isHaveAnyJackpotWin">
                                                                    <div class="delete-close" @click="deleteImage('jackpotWin')">
                                                                        <v-icon color="var(--surface-100)">mdi-close</v-icon>
                                                                    </div>
                                                                    <img 
                                                                       :src="getJackpotWinPreview"
                                                                        alt="jackpot win" />
                                                                </template>
                                                                    
                                                                <div v-else class="no-photo-plus" @click="triggerUploadInput('jackpotWin')">
                                                                    <input type="file" ref="imagePreview" accept="image/png, image/jpeg, image/svg+xml, image/x-icon" 
                                                                    style="display: none"
                                                                    id="jackpot-win-input-id" @change="uploadJackpotWin($event)">
                                                                    <v-icon color="var(--surface-900)">mdi-plus</v-icon>
                                                                </div>
                                                                
                                                            </button>
                                                            <b class="mt-1">
                                                                {{$t('jackpot_win')}}<span class="c-red">*</span>
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="rebuild-ava__item">
                                                                                  <span class="mt-1 form-switch">
                                                    <h4 class="c-text white-space mr-2">{{ $t('title') }}<span class="c-red">*</span>:</h4>
                                                    <div class="d-flex wrapper-input">
                                                      <InputText class="p-inputtext-sm" v-model="styles.title" 
                                                                                          :placeholder="$t('title')"/>
                                                    </div>
                                                </span>
                                                </div>
                                            </div>
                                            <div class="column-three column-three_no-padding rebuild-ava__text font-weight-bold">
                                                {{$t('logo_descr')}}
                                            </div>
                                            <div class="column-three column-three_no-padding">  </div>
                                        </div>
                                        
                                        <div class="rebuild-ava__content">
                                            <div class="d-flex align-center mb-1">
                                                <div class="text-left font-weight-bold">
                                                    {{$t('color_gamma')}}<span class="c-red">*</span>
                                                </div>
                                                <v-spacer></v-spacer>
                                                <div class="p-buttonset text-right">
                                                    <Button 
                                                    class="p-button-sm p-button-success"
                                                    icon="pi pi-plus"
                                                    @click="toAddColor"
                                                    :loading="loadingFlag"
                                                    :label="$t('add_color')"
                                                    v-tooltip.top="$t('click_button_to_save')"
                                                    /> 
                                                    <Button 
                                                    :disabled="isSameColors"
                                                    @click="resetColors"
                                                    :loading="loadingFlag"
                                                    :label="$t('reset')"
                                                    icon="pi pi-refresh"
                                                    class="p-button-sm p-button-danger" />
                                                </div>
                                            </div>
                                            
                                            <!-- <Skeleton width="100%" height="150px" v-if="loadingFlag"></Skeleton>
                                                <Textarea 
                                                v-else
                                                placeholder="{}"
                                                v-model="testJSON" 
                                                rows="5" /> -->
                                           <DataTable 
                                    responsiveLayout="scroll"
                                    :value="currentCSS" 
                                    :rows="currentCSS.length"
                                    :loading="loadingFlag" 
                                    :paginator="true"
                                    stripedRows class="p-datatable-sm">
                                    <template #empty>
                                        {{ $t('no_data') }}
                                    </template>
                                    <Column field="key" :header="$t('name')" :sortable="true" :styles="{'width':'10rem'}">
                                        <template #body="{data}">  
                                        <div>
                                            {{ data.key }}
                                            </div>
                                            </template>                      
                                    </Column>
                                    <Column field="value" :header="$t('value')" :sortable="true" :styles="{'width':'10rem'}">
                                        <template #body="{data}">  
                                        <div class="color-value">
                                            <p>
                                                {{ data.value }}
                                                </p>
                                                <div class="color-preview" :style="`background-color: ${data.value};`"></div>
                                        </div>
                                            </template>                      
                                    </Column>

                                    
                                    <Column :exportable="false" :styles="{'width':'40rem'}">
                                        <template #body="{data, index}">
                                            <span class="p-buttonset d-flex align-center">
                                                <Button @click="toEditColor(data)" :disabled="loadingFlag || data.loadingItem" :loading="loadingFlag || data.loadingItem" :label="$t('edit')"
                                                icon="pi pi-pencil" class="p-button-sm p-button-warning" />
                                                <Button @click="toDeleteColor(index)" 
                                                :disabled="loadingFlag || data.loadingItem" :loading="loadingFlag || data.loadingItem" 
                                                :label="$t('delete')"
                                                aria:haspopup="true" :aria-controls="`overlay_panel_${index}`"
                                                icon="pi pi-trash" class="p-button-sm p-button-danger" />
                                            </span>
                                            <!-- class="admin-overlay" -->
                                            <OverlayPanel :ref="`op_${index}`" appendTo="body" :id="`overlay_panel_${index}`" class="p-confirm-popup">
                                                <div class="p-confirm-popup-content">
                                                <i class="p-confirm-popup-icon pi pi-exclamation-triangle"></i>
                                                <div class="p-confirm-popup-message d-flex flex-column" 
                                style="max-width:400px"
                                >
                                    <h3>{{ $t('attention_title') }}</h3>
                                    <span>{{ $t('color_will_deleted') }}</span>
                                    <h4 style="margin-top: 5px;">{{ $t('attention_question') }}</h4>
                                </div>
                                                </div>
                                                <div class="p-confirm-popup-footer">
                                                    <span class="p-buttonset">
                                                        <Button @click="toCloseOverlay(index)" :disabled="data.loadingItem" :loading="data.loadingItem" 
                                                        :label="$t('no')"
                                                        class="p-button-sm p-button-warning" />
                                                        <Button @click="toConfirmDelete(data, index)" 
                                                        :disabled="data.loadingItem" :loading="data.loadingItem" 
                                                        :label="$t('confirm')"
                                                        class="p-button-sm p-button-danger" />
                                                    </span>
                                                </div>
                                            </OverlayPanel>
                                        </template>
                                    </Column>
                                    </DataTable>
                                                </div>
                                            <!-- @input="updateJSON"   -->
                                        <div class="rebuild-ava__actions">
                                            <v-dialog v-model="dialogModal" content-class="br-16" :transition="false" max-width="500px" scrollable>
                                                <add-color 
                                                v-if="isAddColor"
                                                @add-color="updateAddColors"
                                                 :allColors="currentCSS"
                                                />
                                                <edit-color 
                                                v-else-if="isEditColor"
                                                :color="currentColor"
                                                :allColors="currentCSS"
                                                @edit-color="updateEditColors"
                                                />
                                            </v-dialog>
                                        </div>
                                    </div>
                                </div>
                                
                </template>
            </Card>
        </div>
</template>

<script>
import AddColor from './AddColor'
import EditColor from './EditColor'
import { mapGetters } from 'vuex';

    export default {
        components:{
            AddColor,
            EditColor
        },
        name: 'ThemeCustomize',
        beforeRouteEnter(to, from, next){
            next(vm=>{
                vm.$store.commit('setPageName', 'theme_logo')
            })
        },
        data() {
            return {
                domain: window.location.hostname.includes("take")
                ? process.env.VUE_APP_API_DOMAIN_FIRST
                : process.env.VUE_APP_API_DOMAIN_SECOND,
                currentCSS: [],
                currentColor: null,
                dialogModal: false,
                isEditColor: false,
                isAddColor: false,
                currentLogo: '',
                currentJackpotWin: '',
                currentFavicon: '',
                pageNoWork: false,
                loadingFlag: false,
                newLogo: {
                    preview: null,
                    logo_file: null,
                },
                newFavicon: {
                    preview: null,
                    logo_file: null,
                },
                jackpotWin: {
                    preview: null,
                    logo_file: null,
                },
                constCSS :{
        "--blue": "#5025d1",
        "--gray": "#616470",
        "--black": "#000000",
        "--white": "#ffffff",
        "--red": "#fc5185",
        "--yellow": "#ffe927ff",
        "--gray-text": "#585d6d",
        "--black-text": "#161421",
        "--light-blue": "#7074bf",
        "--design-white": "#f1f2f9",
        "--green-button": "#00bd71",
        "--white-design": "#d3d7db",
        "--dark-flamingo": "#4a1558",
        "--green-success": "#4caf50",
        "--main-flamingo": "#8e24aa",
        "--white-details": "#ebe4ff",
        "--light-flamingo": "#b339d4",
        "--third-flamingo": "#2f1c6a",
        "--dark-d-flamingo": "#2f053a",
        "--second-flamingo": "#6747c7",
        "--flamingo-for-red": "#741b8d"
                },
                constKeltCSS :{
        "--blue": "#5025d1",
        "--gray": "#616470",
        "--black": "#000000",
        "--white": "#ffffff",
        "--red": "#fc5185",
        "--yellow": "#ffe927ff",
        "--gray-text": "#585d6d",
        "--black-text": "#161421",
        "--light-blue": "#7074bf",
        "--design-white": "#f1f2f9",
        "--green-button": "#00bd71",
        "--white-design": "#d3d7db",
        "--dark-flamingo": "#4a1558",
        "--green-success": "#4caf50",
        "--main-flamingo": "#181a1b",
        "--white-details": "#ebe4ff",
        "--light-flamingo": "#b339d4",
        "--third-flamingo": "#2f1c6a",
        "--dark-d-flamingo": "#2f053a",
        "--second-flamingo": "#6747c7",
        "--flamingo-for-red": "#741b8d"
                },
            }
        },
        watch: {
            dialogModal: {
                handler(val, old) {
                    if (val === false) {
                        if (this.isAddColor) {
                            this.isAddColor = false;
                        } else if (this.isEditColor) {
                            this.isEditColor = false;
                        }
                    }
                }
            },
            'styles.css_json': {
                handler(newValue, oldValue) {
                    if (newValue) {
                        // this.currentCSS = JSON.stringify(newValue, null, 2);
                        this.currentCSS = this.variablesCSS;
                    }
                },
                immediate: true,
            },
            isAgent: {
                handler(val, old) {
                    this.$nextTick(() => {
                        
                        this.currentCSS = [];
                        this.currentColor = null;
                        this.currentLogo = '';
                        this.currentFavicon = '';
                        this.newLogo = {
                        preview: null,
                        logo_file: null,
                    }
                        this.newFavicon = {
                        preview: null,
                        logo_file: null,
                    }
                    })
            },
            immediate: true,
            }
        },
        computed: {
            ...mapGetters({
                styles: 'themes/getUI',
            }),
            isSameColors() {
                return this.deepEqual(this.constVariablesCSS, this.currentCSS);
            },
            isHaveAnyLogo() {
                if (this.styles) {
                    if (this.newLogo.preview || this.currentLogo) {
                        return true
                    } else{
                        return false
                    }
                } else{
                    return null
                }
            },
            isHaveAnyFavicon() {
                if (this.styles) {
                    if (this.newFavicon.preview || this.currentFavicon) {
                        return true
                    } else{
                        return false
                    }
                } else{
                    return null
                }
            },
            isHaveAnyJackpotWin() {
                if (this.styles) {
                    if (this.jackpotWin.preview || this.currentJackpotWin) {
                        return true
                    } else{
                        return false
                    }
                } else{
                    return null
                }
            },
            isAdminDomain() {
                return this.domain.includes('kelt') ? 'takemybet.pro' : this.domain
            },
            getJackpotWinPreview() {
                if (this.styles.jackpot_win_img && !this.jackpotWin.preview) {
                    return `https://minio.${this.isAdminDomain}/ui-style/${this.styles.jackpot_win_img}`
                } else{
                    return this.jackpotWin.preview
                }
            },
            getLogoPreview() {
                if (this.styles.logo_img && !this.newLogo.preview) {
                    return `https://minio.${this.isAdminDomain}/ui-style/${this.styles.logo_img}`
                } else{
                    return this.newLogo.preview
                }
            },
            getFaviconPreview() {
                if (this.styles.favicon_img && !this.newFavicon.preview) {
                    return `https://minio.${this.isAdminDomain}/ui-style/${this.styles.favicon_img}`
                } else{
                    return this.newFavicon.preview
                }
            },
            variablesCSS() {
                if (this.styles && this.styles.css_json) {
                    
                    return Object.entries(this.styles.css_json)
                    .map(([key, value], index) => (
                      { key, value, loadingItem: false, index }
      
                    ));  
                } else{
                    return null
                }
            },
            constVariablesCSS() {
                if (this.constCSS && !this.isAgent) {
                    
                    return Object.entries(this.constCSS)
                    .map(([key, value], index) => (
                      { key, value, loadingItem: false, index }
      
                    ));  
                } 
                else if (this.constKeltCSS && this.isAgent) {
                    return Object.entries(this.constKeltCSS)    
                    .map(([key, value], index) => (
                      { key, value, loadingItem: false, index }
      
                    ));  
                }
                else{
                    return null
                }
            },
            isDisabledSave(){
                // this.currentCSS && (this.currentLogo || this.newLogo.logo_file)
                        if (
                            !this.styles.title ||
                            (!this.currentLogo && !this.newLogo.preview) ||
                            (!this.currentFavicon && !this.newFavicon.preview) || 
                            (!this.currentJackpotWin && !this.jackpotWin.preview) || 
                            !this.currentCSS.length) {
                            return true
                        } else{
                            return false
                        }
            },
            isAgent(){
                return this.$route.path.includes('agent');
            },
        },
        mounted() {
            this.getStyles();
        },
        methods: {
            deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true;

if (obj1 == null && obj2 === "") return true;
if (obj1 === "" && obj2 == null) return true;
if (obj1 == null || obj2 == null) return false;
if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

let keys1 = Object.keys(obj1);
let keys2 = Object.keys(obj2);

if (keys1.length !== keys2.length) return false;

for (let key of keys1) {
  if (!keys2.includes(key)) return false;

  // empty string && null
  if (obj1[key] === "" && obj2[key] == null) continue;
  if (obj1[key] == null && obj2[key] === "") continue;

  if (!this.deepEqual(obj1[key], obj2[key])) return false;
}

return true;

},
resetColors(){
    this.currentCSS = this.constVariablesCSS;
},
            toAddColor(){
                this.dialogModal = true;
                this.isEditColor = false;
                this.isAddColor = true;
            },
            toEditColor(color){
                this.dialogModal = true;
                this.isAddColor = false;
                this.isEditColor = true;
                this.currentColor = {...color}
            },
            toCloseOverlay(index){
                this.$refs[`op_${index}`].hide(event);
            },
            toDeleteColor(index){
                this.$refs[`op_${index}`].toggle(event);
            },
            async toConfirmDelete(color, index){
                    this.currentCSS.splice(index, 1);
                    this.$refs[`op_${index}`].hide(event);
            },
            async getStyles(){
                this.pageNoWork = false;
                this.loadingFlag = true;
                let domain = 'takemybet.pro';
                if (this.isAgent) {
                    domain = this.domain;
                }
                try {
                    await this.$store.dispatch('themes/awaitGetStyles', domain);
                    if (this.styles.logo_img) {
                        this.currentLogo = this.styles.logo_img;
                    }
                    if (this.styles.favicon_img) {
                        this.currentFavicon = this.styles.favicon_img;
                    }
                    if (this.styles.jackpot_win_img) {
                        this.currentJackpotWin = this.styles.jackpot_win_img;
                    }
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
                    this.pageNoWork = true;
                } finally {
                    this.loadingFlag = false;
                }
            },
            updateAddColors(color){
                this.isAddColor = false;
                this.dialogModal = false;
                this.currentCSS.unshift(color);
            },
            updateEditColors(color){
                this.isEditColor = false;
                this.dialogModal = false;
                    const index = this.currentCSS.findIndex(item => item.index === color.index);
                    if (index !== 1) {
                        this.$set(this.currentCSS, index, color);
                    }
            },
            async toSaveCustomize(){
                this.loadingFlag = true;
                const form = new FormData();
                let domain = 'takemybet.pro';
                if (this.isAgent) {
                    domain = this.domain;
                }
                form.append('domain', domain);
                let jsonResult = [];
                if (this.currentCSS.length) {
                    jsonResult = JSON.stringify(
                      this.currentCSS.reduce((acc, item) => {
                        acc[item.key] = item.value
                        return acc
                      }, {})
                    );
                    // form.append('css_json', JSON.stringify(this.currentCSS));
                }
                form.append('css_json', jsonResult);
                form.append('title', this.styles.title);
                if (this.jackpotWin.logo_file) {
                    form.append('jackpot_win_img', this.jackpotWin.logo_file)
                }
                if (this.newFavicon.logo_file) {
                    form.append('favicon_img', this.newFavicon.logo_file)
                }
                if (this.newLogo.logo_file) {
                    form.append('logo_img', this.newLogo.logo_file)
                }
                try {
                    await this.$store.dispatch('themes/awaitUpdateStyles', form);
                    this.$toast.add({ severity: 'success', summary: this.$t('themes_updated'), life: 4000 });
                    await this.getStyles();
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
                } finally {
                    this.loadingFlag = false;
                }
            },
            triggerUploadInput(type){
                if (type === 'logo') {
                    document.getElementById('logo-input-id').click();
                } else if (type === 'favicon') {
                    document.getElementById('favicon-input-id').click();
                    
                } else if (type === 'jackpotWin') {
                    document.getElementById('jackpot-win-input-id').click();
                }
            },
            deleteImage(type){
                if (type === 'logo') {
                    URL.revokeObjectURL(this.newLogo.preview);
                    this.newLogo.preview = null;
                    this.newLogo.logo_file = null;
                    if (this.currentLogo) {
                        this.currentLogo = null;
                        return;
                    }
                } else if (type === 'favicon') {
                    URL.revokeObjectURL(this.newFavicon.preview);
                    this.newFavicon.preview = null;
                    this.newFavicon.logo_file = null;
                    if (this.currentFavicon) {
                        this.currentFavicon = null;
                        return;
                    }
                    
                } else if (type === 'jackpotWin') {
                    URL.revokeObjectURL(this.jackpotWin.preview);
                    this.jackpotWin.preview = null;
                    this.jackpotWin.logo_file = null;
                    if (this.currentJackpotWin) {
                        this.currentJackpotWin = null;
                        return;
                    }
                }
                
            },
            uploadLogo(event) {
            const file = event.target.files[0];
            if (file && file.size <= 1000000) {
              const imageUrl = URL.createObjectURL(file);
              this.newLogo.preview = imageUrl;
              this.newLogo.logo_file = file;
            } else{
                this.$toast.add({ severity: 'error', summary: this.$t('file_too_large'), life: 4000 });
                return;
            }
    },
            uploadFavicon(event) {
            const file = event.target.files[0];
            if (file && file.size <= 1000000) {
              const imageUrl = URL.createObjectURL(file);
              this.newFavicon.preview = imageUrl;
              this.newFavicon.logo_file = file;
            } else{
                this.$toast.add({ severity: 'error', summary: this.$t('file_too_large'), life: 4000 });
                return;
            }
    },
            uploadJackpotWin(event) {
            const file = event.target.files[0];
            // console.log('file.size', file.size)
            if (file && file.size <= 1000000) {
              const imageUrl = URL.createObjectURL(file);
              this.jackpotWin.preview = imageUrl;
              this.jackpotWin.logo_file = file;
            } else{
                this.$toast.add({ severity: 'error', summary: this.$t('file_too_large'), life: 4000 });
                return;
            }
    },
        },
    }
</script>

<style lang="scss" scoped>
.color-value{
    display: flex;
    align-items: center;
    gap: 4px;
    max-width: 140px;
    justify-content: space-between;
    p{
        margin-bottom: 0;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .color-preview{
        flex: 0 0 25px;
        width: 25px;
        height: 25px;
        padding: 0.75rem 0.75rem;
        border: 1px solid #ced4da;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px;
    }
}
</style>