<template>
    <v-card>
        <v-card-title class="justify-center">
           <h3> {{ $t('add_color') }}</h3>
        </v-card-title>
        <v-card-text class="">
            <div class="d-flex">
                <div class="d-flex flex-column justify-space-around" 
                >
                    <div class="field d-flex flex-column align-start">
                        <label for="name" class="c-text font-weight-bold" :class="{ 'c-red': errorNameFlag || errorSameNameFlag }">
                            {{ $t('name_color') }}
                        </label>
                        <InputText id="name" type="name" class="p-inputtext-sm" :class="{ 'p-invalid': errorNameFlag || errorSameNameFlag }"
                        placeholder="--white"
                        v-model="color.key"
                        />
                        <small v-if="errorNameFlag || errorSameNameFlag" id="name-help" class="p-invalid text-left c-red"> 

                            {{ errorNameFlag ? $t('req_field_or_wrong') : errorSameNameFlag ? $t('same_name') : '' }}
                        </small>
                    </div>
                    <div class="field d-flex flex-column align-start">
                        <label for="value" class="c-text font-weight-bold" :class="{ 'c-red': errorValueFlag }">
                            {{ $t('code') }}
                        </label>
                        <span class="p-input-icon-right">
                            <div class="color-preview" :style="`background-color: ${getColor};`"></div>
                        <InputText id="value" type="text"  
                        class="p-inputtext-sm"
                        :class="{ 'p-invalid': errorValueFlag }"
                        placeholder="#000000"
                        v-model="color.value"
                         />
                        </span>
                         <small v-if="errorValueFlag" id="name-help" class="p-invalid text-left c-red"> 

                            {{ $t('required_field') }}
                        </small>
                    </div>
                    
                </div>
                <v-spacer></v-spacer>
                <div>
                    <ColorPicker v-model="color.value" :defaultColor="color.value" :inline="true" />
                </div>
            </div>
        </v-card-text>
        <v-card-actions>
                          <Button 
                          :disabled="loadingForm" 
                          :loading="loadingForm" 
                          :label="$t('save')"
                          @click="addColor"
                          icon="pi pi-save" 
                          class="p-button-sm p-button-success fw" 
                          />
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'AddColor',
        props: {
            allColors:{
                type: Array,
                required: true,
                default: () => ([])
            }  
        },
       
        data() {
            return {
                loadingForm: false,
                errorValueFlag: false,
                errorSameNameFlag: false,
                errorNameFlag: false,
                color:{
                    key: '--white',
                    value: '#ffffff'
                }
            }
        },
        computed: {
            getColor() {
                if (!this.color.value.startsWith('#')) {
                    return `#${this.color.value}`
                } else{
                    return this.color.value;
                }
            }
        },
        methods: {
            addColor() {
                const res = this.isValid();
                if (!res) {
                    
                    return
                }
                if (!this.color.value.startsWith('#')) {
                    this.color.value = `#${this.color.value}`
                }
                this.$emit('add-color', this.color)
            },
            isValid() {
                const allNames = this.allColors.map(item => item.key)
                this.errorValueFlag = false;
                this.errorSameNameFlag = false;     
                this.errorNameFlag = false;
                if (!this.color.key || !this.color.key.startsWith('--')) {
                    this.errorNameFlag = true;
                    return false
                } else if(allNames.includes(this.color.key)) {
                this.errorSameNameFlag = true;   
                return false  
                }
                if (!this.color.value) {
                    this.errorValueFlag = true;
                    return false
                } 
                return true
            }
        },
    }
</script>

<style lang="scss" scoped>
.p-input-icon-right{
    >div{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0px,-50%);
    }
}
.color-preview{
    flex: 0 0 26px;
    width: 26px;
    height: 26px;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
}
</style>